import React, { useCallback, useEffect, useState } from 'react';
import jsCookie from 'js-cookie';

import { errMsg } from './PageElement/ErrorMessage';
import { useWeb3Modal } from './Web3Modal';
import { clearLocalData, useResetFilterOptions } from 'lib/resetData';
import Modal from './PageElement/Modal';
import { useToggle } from 'ahooks';
import { userProfileTwitterRebinding } from 'api/user_profile/user_profile_twitter_rebinding';
import { userProfileDiscordRebinding } from 'api/user_profile/user_profile_discord_rebinding';
import { addressFormat } from 'lib/nicknameFormat';

const SocialOauth = () => {
  const { toggleWeb3Modal } = useWeb3Modal();
  const { resetFilterOptions } = useResetFilterOptions();
  const [openConfirm, { setLeft: setCloseConfirm, setRight: setOpenConfirm }] =
    useToggle(false);
  const [rebindingToken, setRebindingToken] = useState('');
  const [address, setAddress] = useState('');

  useEffect(() => {
    const code = new URL(location.href).searchParams.get('code');
    const msg = new URL(location.href).searchParams.get('msg');
    const token = new URL(location.href).searchParams.get('token');
    const addressUrl = new URL(location.href).searchParams.get('address');
    const rebinding_token = new URL(location.href).searchParams.get(
      'rebinding_token'
    );

    if (!!localStorage.getItem('loginSocialType')) {
      if (code === '2004' || code === '2005') {
        errMsg(msg as string, 5000);
        toggleWeb3Modal();
        localStorage.removeItem('loginSocialType');
      } else if (!!token) {
        jsCookie.set(
          process.env.NEXT_PUBLIC_AUTHORIZATION_KEY,
          `Bearer ${token}`,
          {
            expires: 6,
          }
        );
        clearLocalData();
        resetFilterOptions();
        localStorage.removeItem('loginSocialType');
        location.reload();
      }
    }

    if (code === '410') {
      setAddress(addressUrl as string);
      setRebindingToken(rebinding_token as string);
      setOpenConfirm();
    } else if (code === '2003') {
      errMsg('Authorized with the wrong Twitter account.');
    }
    if (!!code || !!token || !!rebinding_token) {
      setTimeout(() => {
        history.replaceState('', '', location.origin.concat(location.pathname));
      }, 1000);
    }
  }, [resetFilterOptions, setOpenConfirm, toggleWeb3Modal]);

  const handleConfirm = useCallback(async () => {
    if (localStorage.getItem('socialOauth') === 'TWITTER') {
      const res = await userProfileTwitterRebinding(rebindingToken);
      if (res?.code === 0) {
        setCloseConfirm();
        location.reload();
      } else {
        errMsg(res?.msg);
      }
    } else if (localStorage.getItem('socialOauth') === 'DISCORD') {
      const res = await userProfileDiscordRebinding(rebindingToken);
      if (res?.code === 0) {
        setCloseConfirm();
        location.reload();
      } else {
        errMsg(res?.msg);
      }
    }
  }, [rebindingToken, setCloseConfirm]);

  return (
    <div>
      <Modal
        open={openConfirm}
        handleConfirm={handleConfirm}
        handleCancel={setCloseConfirm}
        title="Reconnect"
      >
        <div className="text-base font-normal">
          This account is already connected to{' '}
          <span className="break-all text-primary-500">
            {addressFormat(address)}
          </span>
          . Are you sure you want to reconnect it to the logged-in address?
        </div>
      </Modal>
    </div>
  );
};

export default SocialOauth;
