import React, { useCallback, useRef } from 'react';
import { useClickAway } from 'ahooks';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setOpenMore } from 'store/features/navSlice';
import {
  Calendar01,
  DiscordFillBrand,
  Gift,
  Home,
  Menu01,
  More02,
  Package02,
  Rocket,
  Ticket01,
  TwitterFillBrand,
  UsersProfiles01,
  X03,
} from 'lib/lucky-x-icons-2';
import Lottie from 'lottie-react';
import fireIcon from 'assets/icons/fireButtonIcon.json';

interface IMoreProps {
  isOnlyConnect?: boolean;
}

const clientConfig = [
  {
    title: 'LuckyGo Plaza',
    icon: <Home size={24} />,
    path: '/raffle',
    blank: false,
  },
  {
    title: 'My raffles',
    icon: <Gift size={24} />,
    path: '/myraffles',
    blank: false,
  },
  {
    title: 'Calendar',
    icon: <Calendar01 size={24} />,
    path: '/calendar',
    blank: false,
  },
  {
    title: 'Project list',
    icon: <Menu01 size={24} />,
    path: '/project',
    blank: false,
  },
];

const launchConfig = [
  {
    title: 'Launchpad',
    icon: <Rocket size={24} />,
    path: '/launchpad',
    blank: false,
    tag: (
      <div className="freeBg flex h-5 items-center justify-center rounded-full px-2 text-xs font-normal text-[#141416]">
        <Lottie animationData={fireIcon} style={{ width: 16 }} />
        NEW
      </div>
    ),
  },
  {
    title: 'My Inscriptions',
    icon: <Package02 size={24} />,
    path: '/myinscriptions',
    blank: false,
  },
  {
    title: 'LuckyMint',
    icon: <Ticket01 size={24} />,
    path: '/luckymint',
    blank: false,
    tag: (
      <div className="freeBg flex h-5 items-center justify-center rounded-full px-2 text-xs font-normal text-[#141416]">
        BETA
      </div>
    ),
  },
];

const builderConfig = [
  {
    title: 'My Community',
    icon: <UsersProfiles01 size={24} />,
    path: '/builder',
    blank: true,
  },
];

const More: React.FC<IMoreProps> = ({ isOnlyConnect = false }) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { openMore } = useAppSelector((state) => state.nav);
  const moreRef = useRef<HTMLDivElement>(null);

  useClickAway(() => {
    dispatch(setOpenMore(false));
  }, moreRef);

  const goPage = useCallback(
    (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
      e.stopPropagation();
      const blank = e.currentTarget.dataset.blank;
      const path = e.currentTarget.dataset.path;
      if (blank === 'true') {
        window.open(path);
      } else {
        open(path, '_self');
      }
      dispatch(setOpenMore(false));
    },
    [dispatch]
  );

  if (isOnlyConnect) {
    return null;
  }

  return (
    <>
      <div
        className="cursor-pointer rounded-full border border-divider-dark p-2 hover:border-neutral-100 lg:hidden"
        onClick={(e) => {
          e.stopPropagation();
          dispatch(setOpenMore(!openMore));
        }}
        ref={moreRef}
      >
        <div className="flex h-7.5 w-7.5 items-center justify-center rounded-full">
          {openMore ? (
            <X03 size={24} color="#fff" />
          ) : (
            <More02 size={24} color="#fff" />
          )}
        </div>
      </div>
      {openMore && (
        <div
          className="fixed left-0 top-20.5 z-10 h-[calc(100vh-48px)] w-full  overflow-y-scroll bg-black/30 backdrop-blur"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="bg-body-dark px-6 pb-10 pt-3">
            <ul className="grid grid-cols-1 gap-3">
              {clientConfig.map((item) => (
                <li
                  className={classNames(
                    'relative flex h-14 cursor-pointer items-center gap-3 text-neutral-300',
                    {
                      '!text-[#72F129]': router.pathname === item.path,
                    }
                  )}
                  key={item.title}
                  data-path={item.path}
                  data-blank={item.blank}
                  onClick={goPage}
                >
                  <div className="flex h-6 w-6 items-center justify-center ">
                    {item.icon}
                  </div>
                  <span className="text-base font-medium">{item.title}</span>
                  {router.pathname === item.path && (
                    <i className="absolute -left-6 top-1/2 -mt-[6px] block h-3 w-[4px] rounded-r-md bg-[#72F129]"></i>
                  )}
                </li>
              ))}
            </ul>
            <div className="my-3 h-px bg-divider-soft-dark"></div>
            <ul className="grid grid-cols-1 gap-3">
              {launchConfig.map((item) => (
                <li
                  className={classNames(
                    'relative flex h-14 cursor-pointer items-center gap-3 text-neutral-300',
                    {
                      '!text-[#72F129]': router.pathname === item.path,
                    }
                  )}
                  key={item.title}
                  data-path={item.path}
                  data-blank={item.blank}
                  onClick={goPage}
                >
                  <div className="flex h-6 w-6 items-center justify-center ">
                    {item.icon}
                  </div>
                  <span className="text-base font-medium">{item.title}</span>
                  {!!item?.tag && <>{item?.tag}</>}
                  {router.pathname === item.path && (
                    <i className="absolute -left-6 top-1/2 -mt-[6px] block h-3 w-[4px] rounded-r-md bg-[#72F129]"></i>
                  )}
                </li>
              ))}
            </ul>
            <div className="my-3 h-px bg-divider-soft-dark"></div>
            <ul className="grid grid-cols-1 gap-3">
              {builderConfig.map((item) => (
                <li
                  className="flex h-14 cursor-pointer items-center gap-3 text-neutral-300"
                  key={item.title}
                  data-path={item.path}
                  data-blank={item.blank}
                  onClick={goPage}
                >
                  <div className="flex h-6 w-6 items-center justify-center ">
                    {item.icon}
                  </div>
                  <span className="text-base font-medium">{item.title}</span>
                </li>
              ))}
            </ul>
            <div className="my-3 h-px bg-divider-soft-dark"></div>
            <div
              className="py-10"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="flex gap-4 text-[#D9D9D9]">
                <a
                  target="_blank"
                  className="flex h-6 w-6 items-center justify-center"
                  href="https://twitter.com/LuckyGo_io"
                  rel="noreferrer"
                >
                  <TwitterFillBrand
                    className="text-neutral-100 hover:text-[#00A3FF]"
                    size={24}
                  />
                </a>
                <a
                  target="_blank"
                  className="flex h-6 w-6 items-center justify-center"
                  href="https://discord.com/invite/Gykdc8PNwv"
                  rel="noreferrer"
                >
                  <DiscordFillBrand
                    className="text-neutral-100 hover:text-[#5865F2]"
                    size={24}
                  />
                </a>
              </div>
              <p className="mt-2 text-sm text-neutral-400">© 2023 LuckyGo.io</p>
              <div className="mt-2 flex gap-4 text-xs text-white">
                <a
                  target="_blank"
                  href="https://luckybuy.gitbook.io/lucky+/"
                  rel="noreferrer"
                >
                  Terms of Use
                </a>
                <a
                  target="_blank"
                  href="https://luckybuy.gitbook.io/lucky+/privacy-policy"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default More;
