import React from 'react';
import { Loader03, X03 } from 'lib/lucky-x-icons-2';
import classNames from 'classnames';

interface IModalProps {
  open: boolean;
  handleCancel: () => void;
  handleConfirm: (value?: any) => void;
  title: string;
  children: React.ReactNode;
  loadingConfirm?: boolean;
  isDelete?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  open,
  handleCancel,
  handleConfirm,
  title,
  children,
  loadingConfirm,
  isDelete,
}) => {
  if (!open) {
    return null;
  }

  return (
    <div
      className="fixed bottom-0 left-0 right-0 z-20 flex h-[calc(100vh-80px)] w-[100vw] items-center justify-center bg-neutral-1000 bg-opacity-80"
      onClick={handleCancel}
    >
      <div
        className="w-[320px] rounded-2xl bg-neutral-900 px-5 py-6 text-base font-normal text-neutral-100 md:w-[420px] lg:w-[456px] lg:px-9 lg:py-8"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex items-center justify-between">
          <p className="text-lg font-semibold italic lg:text-2xl ">{title}</p>
          <X03
            className="-mr-[6px] cursor-pointer text-neutral-400 hover:text-neutral-300"
            size={32}
            onClick={handleCancel}
          />
        </div>
        <div className="my-6 whitespace-pre-wrap text-left">{children}</div>
        <div className="grid grid-cols-2 gap-5 font-medium text-neutral-100 lg:text-lg">
          <div
            className="h-10 cursor-pointer rounded-full border border-neutral-600 text-center leading-10 hover:border-neutral-500 lg:h-[60px] lg:leading-[60px]"
            onClick={handleCancel}
          >
            Cancel
          </div>
          <div
            className={classNames(
              'active:buttonShadow  flex h-10 cursor-pointer items-center  justify-center rounded-full  text-center  leading-10  lg:h-[60px] lg:leading-[60px]',
              {
                'bg-primary-500 hover:bg-primary-600': !isDelete,
                'bg-warn-300 hover:bg-warn-400': isDelete,
              }
            )}
            onClick={() => {
              if (!loadingConfirm) {
                handleConfirm();
              }
            }}
          >
            {loadingConfirm ? (
              <Loader03 size={24} className="animate-spin" />
            ) : (
              <>{isDelete ? 'Delete' : 'Confirm'}</>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
