import React, { useEffect, useMemo, useState } from 'react';
import { useWeb3Modal } from '../Web3Modal';
import { useAddressFormat } from 'lib/nicknameFormat';
import { useBlockies } from 'lib/blockies';
import { useUserInfo } from 'lib/user';
import More from './More';
import Menus from './Menus';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { setOpenMenus } from 'store/features/navSlice';
import { useRouter } from 'next/router';
import { setOnlyConnectType } from 'store/features/web3Slice';

const btcAddrPathname = [
  '/luckymint',
  '/launchpad',
  '/myinscriptions',
  '/launchwithlucky',
  '/l/[vanityUrl]',
  '/mint/[vanityUrl]',
  '/payment/[orderId]',
];
interface IUserProps {
  isHiddenUser?: boolean;
  isOnlyConnect?: boolean;
}

const User: React.FC<IUserProps> = ({
  isHiddenUser = false,
  isOnlyConnect = false,
}) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const { openMenus } = useAppSelector((state) => state.nav);
  const { isLogin, address, discord_avatar, twitter_avatar, btc_address } =
    useUserInfo();
  const { toggleWeb3Modal } = useWeb3Modal();
  const finalAddress = useMemo(() => {
    if (
      btcAddrPathname?.filter((item) => item === router.pathname)?.length !==
        0 &&
      btc_address
    ) {
      return btc_address;
    }
    return address || btc_address || '';
  }, [router, btc_address, address]);
  const nickname = useAddressFormat(finalAddress);
  const avatar = useBlockies(finalAddress);
  const [userLogo, setUserLogo] = useState('');

  useEffect(() => {
    if (!!discord_avatar) {
      setUserLogo(discord_avatar);
    } else if (!!twitter_avatar) {
      setUserLogo(twitter_avatar);
    } else {
      setUserLogo(avatar);
    }
  }, [avatar, discord_avatar, twitter_avatar]);

  if (!isLogin && !isHiddenUser) {
    return (
      <>
        <div
          onClick={() => {
            toggleWeb3Modal();
            dispatch(setOnlyConnectType(''));
          }}
          className="mx-3 flex h-12 cursor-pointer items-center justify-center rounded-full bg-primary-500 px-2 text-base font-normal text-white hover:bg-primary-600 md:px-4"
        >
          <div className="text-sm md:hidden">Connect</div>
          <div className="hidden md:block">Connect Wallet</div>
        </div>
        <More isOnlyConnect={isOnlyConnect} />
      </>
    );
  }
  return (
    <>
      {!isHiddenUser && (
        <div
          className="relative mx-3 flex h-12 cursor-pointer items-center justify-end gap-2 rounded-full border border-divider-dark px-2 hover:border-divider-dark-hovered"
          tabIndex={1}
          onClick={() => {
            dispatch(setOpenMenus(!openMenus));
          }}
          onBlur={() => {
            dispatch(setOpenMenus(false));
          }}
        >
          <img className="h-7.5 w-7.5 rounded-full" src={userLogo} alt="" />
          <div className="hidden text-base font-normal text-white lg:block lg:pr-2">
            {nickname}
          </div>
          <Menus open={openMenus} isOnlyConnect={isOnlyConnect} />
        </div>
      )}
      <More isOnlyConnect={isOnlyConnect} />
    </>
  );
};

export default User;
