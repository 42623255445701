import { createIcon } from '@download/blockies';
import { useEffect, useState } from 'react';

export function useBlockies(address: string) {
  const [avatar, setAvatar] = useState('');
  useEffect(() => {
    const src = createIcon({
      seed: address,
      size: 10,
      scale: 8,
    }).toDataURL();
    setAvatar(src);
  }, [address]);

  return avatar;
}
