import React from 'react';
import Lottie from 'lottie-react';
import fireIcon from 'assets/icons/fireButtonIcon.json';
import { Add03 } from 'lib/lucky-x-icons-2';

interface INewRaffleProps {}

const NewRaffle: React.FC<INewRaffleProps> = (props) => {
  return (
    <div
      className="relative flex h-12 cursor-pointer items-center justify-center gap-2 rounded-full border border-divider-dark px-3 text-[#FCFCFD] hover:border-neutral-100 sm:px-6"
      onClick={() => {
        open('/builder');
      }}
    >
      <Add03 className="hidden lg:inline-block" size={24} />
      <div className="text-base font-normal">New raffle</div>
      <div className="freeBg absolute -top-3 right-0 flex h-5 items-center justify-center rounded-full px-2 text-xs font-normal text-[#141416]">
        <Lottie animationData={fireIcon} style={{ width: 16 }} />
        FREE
      </div>
    </div>
  );
};

export default NewRaffle;
