import request from 'lib/request';

export interface Response {
  code: number;
  msg: string;
}

export async function userProfileDiscordRebinding(rebinding_token: string) {
  const res = await request<Response>('/user_profile/discord_rebinding', {
    method: 'POST',
    data: {
      rebinding_token,
    },
  });
  return res;
}
