import { useUserInfo } from 'lib/user';
import { useRouter } from 'next/router';
import React from 'react';

interface IMenusProps {
  open: boolean;
  isOnlyConnect?: boolean;
}

const Menus: React.FC<IMenusProps> = ({ open, isOnlyConnect = false }) => {
  const { disconnect } = useUserInfo();
  const router = useRouter();
  if (!open) {
    return null;
  }
  return (
    <div className="absolute right-0 top-[54px] w-full min-w-max rounded-2xl border border-divider-dark bg-[#1A1E23] p-2 md:left-0">
      <ul className="cursor-pointer text-sm text-white">
        {!isOnlyConnect && (
          <li
            onClick={() => {
              router.push('/profile');
            }}
            className="rounded-xl p-2 hover:bg-[#21262C]"
          >
            Profile
          </li>
        )}
        <li onClick={disconnect} className="rounded-xl p-2 hover:bg-[#21262C]">
          Disconnect
        </li>
      </ul>
    </div>
  );
};

export default Menus;
