import React, { useCallback, useEffect } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import logo from '@/assets/logo.png';
import siderLogo from 'assets/siderLogo.png';
import { useUserInfo } from 'lib/user';
import NewRaffle from './NewRaffle';
import User from './User';
import SocialOauth from '../SocialOauth';

interface NavBarType {
  btnList?: React.ReactNode;
  isHiddenUser?: boolean;
  isOnlyConnect?: boolean;
}

const NavBar: React.FC<NavBarType> = ({
  btnList,
  isHiddenUser = false,
  isOnlyConnect = false,
}) => {
  const router = useRouter();
  const { address } = useUserInfo();

  useEffect(() => {
    const addrLocal = localStorage.getItem('address');
    if (
      !addrLocal ||
      addrLocal === undefined ||
      addrLocal === null ||
      (!!addrLocal &&
        !!address &&
        addrLocal?.toLocaleLowerCase() !== address?.toLocaleLowerCase())
    ) {
      localStorage.setItem('address', address);
    }
  }, [address]);

  const goPage = useCallback(() => {
    if (router.pathname === '/raffle') {
      location.reload();
    } else {
      router.push('/raffle');
    }
  }, [router]);

  return (
    <div
      className={classNames({
        'lg:hidden lg:!h-0': isHiddenUser,
      })}
    >
      <div
        className={classNames(
          'fixed left-0 top-0 z-[98]  h-20.5 w-full  shrink-0 border-b border-divider-soft-dark border-opacity-70 bg-body-dark',
          {}
        )}
      >
        <div className="flex h-full items-center justify-between px-4">
          <div className="cursor-pointer" onClick={goPage}>
            {isHiddenUser ? (
              <>
                <img
                  className="hidden md:block md:w-[142px]"
                  src={siderLogo.src}
                  alt=""
                />
                <img className="w-12 md:hidden" src={logo.src} alt="" />
              </>
            ) : (
              <img className="w-12 lg:hidden" src={logo.src} alt="" />
            )}
          </div>
          <div className="flex items-center">
            {!isHiddenUser && <>{btnList ? btnList : <NewRaffle />}</>}
            <User isHiddenUser={isHiddenUser} isOnlyConnect={isOnlyConnect} />
          </div>
        </div>
      </div>
      <div className="h-20.5"></div>
      <SocialOauth />
    </div>
  );
};

export default NavBar;
